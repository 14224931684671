// Funzione per riorganizzare la pagina in base alla colonna selezionata
function reorder_page(sort_column) {
    var my_url = new URL(window.location.href);
    var current_sort_order = my_url.searchParams.get('sort_order');

    var new_sort_order;
    if (current_sort_order === 'ascending' && my_url.searchParams.get('sort_column') === sort_column) {
        new_sort_order = 'descending';
    } else if (current_sort_order === 'descending' && my_url.searchParams.get('sort_column') === sort_column) {
        new_sort_order = '';
        my_url.searchParams.delete('sort_order');
        my_url.searchParams.delete('sort_column');
    } else {
        new_sort_order = 'ascending';
    }

    if (new_sort_order) {
        my_url.searchParams.set('sort_order', new_sort_order);
        my_url.searchParams.set('sort_column', sort_column);
    }

    $.ajax({
        type: 'GET',
        url: my_url.toString(),
        dataType: "script",
    }).done(function (response) {
        if (window.history.pushState) {
            window.history.pushState({}, '', my_url.toString());
        }
        initializeSorting();
    }).fail(function (response) {
        console.log('error?', response);
    });
}


function initializeSorting() {
    var headerContainer = document.getElementById('header-container')
    if (!headerContainer) return; 
    headerContainer.addEventListener('click', function (e) {
        const sortColumnMap = {
            'asset-name-header': 'asset_name',
            'asset-model-header': 'asset_model',
            'user-header': 'user',
            'date-time-header': 'date_time',
            'event-type-header': 'event_type',
            'event-detail-header': 'event_detail'
        };
    
        if (sortColumnMap[e.target.id]) {
            e.preventDefault();
            reorder_page(sortColumnMap[e.target.id]);
        }
    });
}


document.addEventListener('turbolinks:load', function () {
    initializeSorting();
});
