function initializeViewSwitcher() {
    var viewTableButton = document.getElementById('view-table');
    var viewCardsButton = document.getElementById('view-cards');
    var tableView = document.getElementById('table');
    var cardView = document.getElementById('list_card');

    if (!viewTableButton || !viewCardsButton || !tableView || !cardView) return;

    function showTableView() {
        tableView.style.display = 'block';
        cardView.style.display = 'none';
        viewTableButton.classList.add('active');
        viewCardsButton.classList.remove('active');
        localStorage.setItem('preferred_view', 'table');
    }

    function showCardView() {
        tableView.style.display = 'none';
        cardView.style.display = 'block';
        viewTableButton.classList.remove('active');
        viewCardsButton.classList.add('active');
        localStorage.setItem('preferred_view', 'cards');
    }

    viewTableButton.addEventListener('click', showTableView);
    viewCardsButton.addEventListener('click', showCardView);

    var preferredView = localStorage.getItem('preferred_view');
    if (preferredView === 'table') {
        showTableView();
    } else if (preferredView === 'cards') {
        showCardView(); 
    }
}

document.addEventListener("turbolinks:load", function() {
    initializeViewSwitcher();
});